import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { CImage } from '@coreui/react-pro'
import cubotooLogoWhite from 'src/assets/images/avatars/logo-cubotoo-white.svg'

const OrientationMessage = () => {
  const { t } = useTranslation()

  const [isMobileOrTablet, setIsMobileOrTablet] = useState(false)

  useEffect(() => {
    const checkScreenSize = () => {
      const mediaQuery = window.matchMedia('(orientation: portrait)')
      setIsMobileOrTablet(mediaQuery.matches)
    }

    checkScreenSize()
    window.addEventListener('resize', checkScreenSize)

    return () => {
      window.removeEventListener('resize', checkScreenSize)
    }
  }, [])

  return (
    <>
      {isMobileOrTablet ? (
        <div className="orientation-fullscreen-overlay d-flex flex-column position-fixed">
          <div className="bg-black text-center py-4">
            <CImage
              rounded
              src={cubotooLogoWhite}
              className="mx-auto my-2"
              fluid
              width={181.21}
              height={53.49}
            />
          </div>
          <div className="d-flex flex-column justify-content-center align-items-center text-center px-4 orientation-overlay-text">
            <p>{t('Orientation.OrientationMessageTitle')}</p>
            <p>{t('Orientation.OrientationMessageDescription')}</p>
          </div>
        </div>
      ) : null}
    </>
  )
}

export default OrientationMessage
